<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      flat
      prominent
    >
      <v-app-bar-title>L&amp;D</v-app-bar-title>

      <v-tabs
        centered
        class="ml-n9"
        color="grey darken-1"
      >
        <v-tab
          v-for="link in links"
          :key="link"
          @click="$router.push(link.path)"
        >
          {{ link.text }}
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view/>
    </v-main>

    <v-footer
    color="white lighten-1"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link"
        @click="$router.push(link.path)"
        text
        rounded
        class="my-2"
      >
        {{ link.text }}
      </v-btn>
      <v-col
        class="primary lighten-2 py-4 text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Colorizame</strong>
      </v-col>
    </v-row>
  </v-footer>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      links: [
        {text:'Inicio',path:'/'},
        {text:'EN VIVO',path:'/live'},
        {text:'Nosotros',path:'/about'},
        {text:'Dejanos un mensaje',path:'/board'},
        {text:'Apoyanos',path:'/support'}
      ],
    })
  }
</script>