<template>
    <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="2"
          >
            <v-sheet
              rounded="lg"
              v-show="(remainingTime() > 0)"
            >
            <v-parallax
              dark
              src="/img/blurred1.jpg"
              height="300"
            >
              <v-row
                align="center"
                justify="center"
              >
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <h1 class="text-h4 font-weight-thin mb-2">
                    Civil
                  </h1>
                  <h4 class="subheading">
                    <countdown :time="remainingTime()">
                      <template slot-scope="props">{{ props.days }} días, {{ props.hours }} horas, {{ props.minutes }} minutos, {{ props.seconds }} segundos.</template>
                    </countdown>
                  </h4>
                </v-col>
              </v-row>
            </v-parallax>
            </v-sheet>
            <v-sheet
              rounded="lg"
              min-height="268"
              v-show="(remainingTime() < 0)"
            >
              <v-parallax
              dark
              src="/img/blurred2.jpg"
              height="300"
            >
              <v-row
                align="center"
                justify="center"
              >
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <h1 class="text-h4 font-weight-thin mb-2">
                    Religioso
                  </h1>
                  <h4 class="subheading">
                    <countdown :time="remainingTimeR()">
                      <template slot-scope="props">{{ props.days }} días, {{ props.hours }} horas, {{ props.minutes }} minutos, {{ props.seconds }} segundos.</template>
                    </countdown>
                  </h4>
                </v-col>
              </v-row>
            </v-parallax>
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            sm="8"
          >
            <v-sheet
              min-height="70vh"
              rounded="lg"
              class="pa-5"
            >
              <img src="/img/NuestraBoda.png" style="max-width:100%" alt="">
              <p>Lu &amp; Dani te invitan a compartir con ellos este momento especial, a través de participar de su casamiento civil a realizarse en <b>Registro Civil (Puiggari)</b></p>
              <p v-if="!$route.query.invite">Y a ver la transmisión en vivo de la ceremonia de casamiento en la pestaña <b>EN VIVO</b> de esta página.</p>
              <p>Apreciaríamos mucho que nos acompañes online y nos dejes en la sección <b>DEJANOS UN MENSAJE</b> un mensaje, consejo, felicitación, abrazo, etc.  </p>
              <p v-if="$route.query.invite">Adicionalmente, exclusivamente para ti tienes la invitación a participar del evento que es la ceremonia religiosa a llevarse a cabo en <b>Calle Sarmiento, Zona Rural</b>, Libertador San Martín, Entre Ríos.</p>
              <a target="_blank" href="https://goo.gl/maps/K6jkrjLXzYTTHPa16">Ver ubicación Registro Civil en Google Maps</a>
              <br>
              <a v-if="$route.query.invite" target="_blank" href="https://goo.gl/maps/g6pM5ep2qW7Hizzy8">Ver ubicación Ceremonia religiosa en Google Maps</a>
              <iframe style="max-width:100%" v-if="!$route.query.invite" src="https://www.google.com/maps/d/u/0/embed?mid=1aFOrPnsEXB-6M_tMr7psReQ-pC5cUeg&ehbc=2E312F" width="900" height="480"></iframe>
              <iframe style="max-width:100%" v-if="$route.query.invite" src="https://www.google.com/maps/d/u/0/embed?mid=1zUpHzeIRuW8N79EV1v-E2eFtKJPPj_Q&ehbc=2E312F" width="900" height="480"></iframe>
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            sm="2"
          >
            <v-sheet
              rounded="lg"
              min-height="268"
              v-show="(remainingTime() > 0)"
            >
              <v-parallax
              dark
              src="/img/blurred2.jpg"
              height="300"
            >
              <v-row
                align="center"
                justify="center"
              >
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <h1 class="text-h4 font-weight-thin mb-2">
                    Religioso
                  </h1>
                  <h4 class="subheading">
                    <countdown :time="remainingTimeR()">
                      <template slot-scope="props">{{ props.days }} días, {{ props.hours }} horas, {{ props.minutes }} minutos, {{ props.seconds }} segundos.</template>
                    </countdown>
                  </h4>
                </v-col>
              </v-row>
            </v-parallax>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  methods: {
      remainingTime() {
        let future = Date.parse('19 Oct 2022 10:30:00 GMT-3')
        let now = new Date()
        let timeLeft = future - now
        console.log(timeLeft)
        return timeLeft
      },
      remainingTimeR() {
        let future = Date.parse('19 Oct 2022 11:35:00 GMT-3')
        let now = new Date()
        let timeLeft = future - now
        console.log(timeLeft)
        return timeLeft
      }
    }
  // components: {
  //   HelloWorld
  // }
}
</script>
